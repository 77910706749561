import { CommonModule } from '@angular/common';
import { RecommendationReviewStatus } from '@shared/models';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ResolvedFault } from '@app/shared/models';
import { ButtonV2Module, SelectOption } from '@gea/digital-ui-lib';
import { HeadingComponent, CloseButtonComponent } from '@app/shared/components';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [CommonModule, HeadingComponent, CloseButtonComponent, MatDialogModule, ButtonV2Module, TranslateModule],
  selector: 'gea-hrt-resolved-fault-detail-dialog',
  templateUrl: './detail-dialog.component.html',
  styleUrls: ['./detail-dialog.component.scss'],
})
export class SolvedFaultsDetailDialogComponent {
  formGroup: FormGroup;

  selectedRecommendations: SelectOption<number>[] = [];

  constructor(
    readonly dialogRef: MatDialogRef<SolvedFaultsDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: ResolvedFault
  ) {
    this.formGroup = this.createFormGroup(data);
  }

  private createFormGroup(data: ResolvedFault): FormGroup {
    return new FormGroup({
      additionalNote: new FormControl(data.additionalNote || ''),
      selectedRecommendations: new FormControl([]),
    });
  }

  setReviewStatus(reviewStatus: RecommendationReviewStatus): void {
    if (this.data.status === reviewStatus) {
      this.closeDialog();
    } else {
      this.dialogRef.close({ resolvedFault: this.data, reviewStatus });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
