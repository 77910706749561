import { Component, Input } from '@angular/core';
import { IconModule } from '@gea/digital-ui-lib';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  imports: [TranslateModule, IconModule],
  selector: 'gea-hrt-empty-data-placeholder',
  templateUrl: './empty-data-placeholder.component.html',
  styleUrls: ['./empty-data-placeholder.component.scss'],
})
export class EmptyDataPlaceholderComponent {
  @Input({ required: true }) text!: string;

  constructor(readonly translate: TranslateService) {}
}
