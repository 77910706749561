import { Permission, RecommendationDto } from '@shared/models';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ButtonV2Module, IconModule } from '@gea/digital-ui-lib';

@Component({
  imports: [CommonModule, ButtonV2Module, IconModule, TranslateModule],
  selector: 'gea-hrt-recommendation-editable-actions',
  templateUrl: './editable-actions.component.html',
  styleUrl: './editable-actions.component.scss',
})
export class EditableActionsComponent {
  @Input({ required: true }) recommendation!: RecommendationDto;
  @Input() permission?: Permission;

  @Output() setRecommendationUseful = new EventEmitter<RecommendationDto>();
  @Output() openEditRecommendationDialog = new EventEmitter<RecommendationDto>();
  @Output() openDeleteRecommendationDialog = new EventEmitter<RecommendationDto>();
  @Output() validateRecommendation = new EventEmitter<RecommendationDto>();

  constructor(public readonly translate: TranslateService) {}
}
