import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { HeadingComponent, CloseButtonComponent, ImagesPreviewComponent } from '@app/shared/components';
import { RecommendationDto } from '@app/shared/models';
import { ButtonV2Module, TextfieldV2Module } from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [
    CommonModule,
    HeadingComponent,
    CloseButtonComponent,
    MatDialogModule,
    TextfieldV2Module,
    ImagesPreviewComponent,
    ButtonV2Module,
    TranslateModule,
  ],
  selector: 'gea-hrt-recommendation-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class RecommendationDialogComponent {
  formGroup!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<RecommendationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: { recommendation?: RecommendationDto }
  ) {
    this.formGroup = new FormGroup({
      recommendation: new FormControl(data?.recommendation?.actionName ?? '', [Validators.minLength(10), Validators.required]),
      images: new FormControl<(string | ArrayBuffer[])[]>(this.data?.recommendation?.images ?? []),
    });
  }

  save() {
    const value = this.formGroup.value;
    const actionName = value.recommendation;
    const images = value.images ?? [];

    this.dialogRef.close({
      actionName,
      images,
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
