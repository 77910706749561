import { Injectable } from '@angular/core';


import { HrtApiService } from '@shared/services/hrt-api/hrt-api.service';

@Injectable({
  providedIn: 'root',
})
export class DemoaccountApiService {
  constructor(readonly api: HrtApiService) {}

  createData() {
    return this.api.post('DemoAccount/create', null);
  }
}
