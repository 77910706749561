import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnDestroy, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { CommonModule } from '@angular/common';
import { ButtonV2Module } from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';

import { RecommendationDto, RecommendationStatus, ProblemResolveDto, Permission } from '@shared/models';
import { OrganizationsState } from '@shared/state';
import { ContactDialogComponent, ProblemSolveDialogComponent } from './';

@Component({
  imports: [CommonModule, ButtonV2Module, TranslateModule],
  selector: 'gea-hrt-troubleshooting-problem-solve',
  templateUrl: './problem-solve.component.html',
  styleUrls: ['./problem-solve.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProblemSolveComponent implements OnDestroy {
  permission$?: Observable<Permission | undefined>;
  constructor(
    readonly dialog: MatDialog,
    readonly store: Store
  ) {
    this.permission$ = this.store.select(OrganizationsState.permission);
  }

  @Input() showText = true;

  @Input({ required: true }) list: RecommendationDto[] = [];

  @Output() emitProblemSolved = new EventEmitter<ProblemResolveDto | undefined>();

  closeDialog$!: Subscription;

  ngOnDestroy() {
    if (this.closeDialog$) {
      this.closeDialog$.unsubscribe();
    }
  }

  problemSolvedClicked() {
    if (this.list.some(({ status }) => status === RecommendationStatus.DONE)) {
      this.emitProblemSolved.emit();
      return;
    }
    this.openSolutionDescriptionDialog();
  }

  openContactDialog() {
    const dialog = this.dialog.open(ContactDialogComponent, {
      width: '100rem',
      minHeight: '50vh',
    });

    this.closeDialog$ = dialog.afterClosed().subscribe(() => ({}));
  }

  openSolutionDescriptionDialog() {
    const dialog = this.dialog.open(ProblemSolveDialogComponent, {
      width: '100rem',
      minHeight: '50vh',
    });

    dialog.componentInstance.recommendations = this.list;

    this.closeDialog$ = dialog.afterClosed().subscribe((props: ProblemResolveDto) => {
      if (!props) {
        // user closed/canceled the modal -> do nothing
        return;
      }
      this.emitProblemSolved.emit(props);
    });
  }
}
