import { ResolvedFault } from '@shared/models';
import { Component, inject } from '@angular/core';
import { ButtonV2Module, ColumnRendererComponent, IconModule, TableServiceV2 } from '@gea/digital-ui-lib';
import { VIEW_SOLVED_FAULT_DETAIL } from '../solved-faults.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [ButtonV2Module, IconModule, TranslateModule],
  selector: 'gea-hrt-solved-fault-button-renderer',
  template: `
    <div>
      <geaui-button-v2
        type="secondary"
        (click)="onClick()"
        [title]="'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.VIEW_DETAILS' | translate">
        <geaui-icon-v2 file="eye" class="fix_key" />
        {{ 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.VIEW' | translate }}
      </geaui-button-v2>
    </div>
  `,
  styles: `
    div {
      width: fit-content;
      margin: 0 16px;
      cursor: pointer;
      font-size: 18px;
      font-weight: 500;
      color: var(--geaui-vibrant-blue);
    }
    div:hover {
      text-decoration: underline;
    }
    ::ng-deep .p-button {
      height: unset !important;
    }
    ::ng-deep .p-button-content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.2rem;
    }
  `,
})
export class SolvedFaultViewDetailRendererComponent implements ColumnRendererComponent<ResolvedFault> {
  rowData?: ResolvedFault;
  tableService = inject(TableServiceV2);
  tableId = '';

  onClick() {
    this.tableService.publishAction(this.tableId, VIEW_SOLVED_FAULT_DETAIL, this.rowData);
  }
}
