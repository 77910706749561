import { Component } from '@angular/core';
import { IconModule } from '@gea/digital-ui-lib';

@Component({
  imports: [IconModule],
  selector: 'gea-hrt-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
})
export class HintComponent {}
