import { CommonModule } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { HeadingComponent } from '../heading';
import { IconModule } from '@gea/digital-ui-lib';
import { TooltipModule } from 'primeng/tooltip';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [CommonModule, HeadingComponent, IconModule, TooltipModule, MatCardModule, TranslateModule],
  selector: 'gea-hrt-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBoxComponent {
  @Input()
  boxTitle = '';

  @Input()
  tooltipHeading = '';

  @Input()
  tooltip = '';

  @Input()
  withBorder? = false;
}
