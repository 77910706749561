import { Component } from '@angular/core';

import { ColumnRendererComponent } from '@gea/digital-ui-lib';
import { Shutdown, Warning } from '@shared/models';

interface ProgressBarConfig {
  barHeight: number;
  countColor: string;
  totalCountColor: string;
}

@Component({
  selector: 'gea-hrt-progress-bar-renderer',
  standalone: true,
  templateUrl: './progress-bar-renderer.component.html',
  styleUrl: './progress-bar-renderer.component.scss',
})
export class ProgressBarRendererComponent implements ColumnRendererComponent<Warning | Shutdown> {
  rowData?: Warning | Shutdown;
  columnKey = '';
  config: ProgressBarConfig = {
    barHeight: 20,
    countColor: 'var(--geaui-yellow)',
    totalCountColor: 'var(--geaui-light-gray)',
  };

  getCountWidth(): number {
    const count = this.extractCount();
    return count * 70; // 100% - 30px
  }

  getTotalCountWidth(): number {
    const count = this.extractCount();
    return (1 - count) * 70; // 100% - 30px
  }

  private extractCount() {
    if (!this.rowData) return 0;

    const count = Number(this.rowData[this.columnKey]);

    if (isNaN(count)) return 0.01;
    return count;
  }
}
