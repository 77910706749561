import { FaultCardsRenderComponent } from './renderer/fault-cards-renderer/fault-cards-render.component';
import { TextOrganizationNameRenderComponent } from './renderer/text-organization-name-renderer/text-organization-name-render.component';
import { ColumnDefinition, ColumnRendererComponent } from '@gea/digital-ui-lib';
import { Organization } from '@shared/models';

export const customerOverviewColumnDefinitions: ColumnDefinition[] = [
  {
    key: 'name',
    displayName: 'CUSTOMER_OVERVIEW.CUSTOMER',
    sortable: true,
    width: 1,
    resizeable: true,
    renderer: {
      component: TextOrganizationNameRenderComponent as ColumnRendererComponent<Organization>,
    },
  },
  {
    key: 'fault',
    displayName: 'CUSTOMER_OVERVIEW.FAULT',
    sortable: false,
    renderer: {
      component: FaultCardsRenderComponent as ColumnRendererComponent<Organization>,
    },
  },
  {
    key: 'row-end',
    displayName: '',
    width: 1,
  },
];
