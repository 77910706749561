<div class="fault-analysis-container">
  <div #filters class="section filters">
    <ng-container *ngIf="selectedMachines$ | async as selectedMachines">
      <ng-container *ngIf="selectedFaults$ | async as selectedFaultCodes">
        <gea-hrt-fault-filters
          [dateRange]="dateRange"
          [machineOptions]="machineOptions ?? []"
          [faultCodeOptions]="faultOptions ?? []"
          [selectedMachines]="selectedMachines | toSelectOptions"
          [selectedFaultCodes]="selectedFaultCodes | toSelectOptions"
          (setMachines)="setSelectedMachines($event)"
          (setFaultCodes)="setSelectedFaultCodes($event)"
          (setDateRange)="setDateRange($event)"
          (setStatus)="setStatus($event)" />
      </ng-container>
    </ng-container>
  </div>

  <div class="section shutdowns" *ngIf="shutdowns$ | async as shutdowns">
    @defer (when shutdowns) {
      <gea-hrt-fault-analysis-section [data]="shutdowns" type="shutdown" (resolveFaultCodeOutput)="resolveFaultCode($event)" />
    } @placeholder {
      <gea-hrt-spinner />
    }
  </div>

  <div class="section warnings" *ngIf="warnings$ | async as warnings">
    @defer (when warnings) {
      <gea-hrt-fault-analysis-section [data]="warnings" type="warning" (resolveFaultCodeOutput)="resolveFaultCode($event)" />
    } @placeholder {
      <gea-hrt-spinner />
    }
  </div>

  <div class="section fault-history" *ngIf="faultHistory$ | async as faultHistory">
    @defer (when faultHistory) {
      <gea-hrt-fault-history [faultHistory]="faultHistory" />
    } @placeholder {
      <gea-hrt-spinner />
    }
  </div>
</div>
