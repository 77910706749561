import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Permission, RecommendationDto } from '@app/shared/models';
import { IconModule } from '@gea/digital-ui-lib';

import { RecommendationAction } from '@troubleshooting/recommendations';
import { EditableActionsComponent } from '@troubleshooting/recommendations/editable-actions';
import { ReadonlyActionsComponent } from '@troubleshooting/recommendations/readonly-actions';

@Component({
  imports: [CommonModule, IconModule, EditableActionsComponent, ReadonlyActionsComponent],
  selector: 'gea-hrt-recommendation-item',
  templateUrl: './recommendation-item.component.html',
  styleUrl: './recommendation-item.component.scss',
})
export class RecommendationItemComponent {
  @Input({ required: true }) recommendation!: RecommendationDto;
  @Input() permission?: Permission;
  @Input() index?: number;
  @Input() isEditable = false;
  @Input() isDraggable = false;

  @Output() openImagesDialog = new EventEmitter<(ArrayBuffer[] | string)[]>();
  @Output() openDeleteRecommendationDialog = new EventEmitter<RecommendationDto>();
  @Output() actionClicked = new EventEmitter<RecommendationAction>();
  @Output() openEditRecommendationDialog = new EventEmitter<RecommendationDto>();
  @Output() setRecommendationUseful = new EventEmitter<RecommendationDto>();
  @Output() validateRecommendation = new EventEmitter<RecommendationDto>();
}
