import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { OrganizationsState } from '@shared/state';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import { OrganizationPermission } from '@shared/models';
import { CommonModule } from '@angular/common';
import { ButtonV2Module } from '@gea/digital-ui-lib';
import { DemoaccountApiService } from '@shared/services';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';

@Component({
  imports: [CommonModule, ButtonV2Module],
  template: `<div *ngIf="Visibility">
    <geaui-button-v2 (click)="resetData()" [loading]="isLoading">Reset Demodata</geaui-button-v2>
  </div>
  `,
  selector: 'gea-hrt-reset-demoaccout',
  standalone: true,

})
export class ResetDemoaccoutComponent implements OnInit {
  @Select(OrganizationsState.selected) organizationPermission$?: Observable<OrganizationPermission>;
  ngDestroyed$ = new Subject<void>();

  isVezet = false;
  isLoading = false;
  isFaultAnalysisTab = false;

  constructor(
    readonly service: DemoaccountApiService,
    readonly router: Router
  ) {}
  ngOnInit() {
    this.isFaultAnalysisTab = this.router.url.includes('fault-analysis');
    
    this.router.events.pipe(filter((a) => a instanceof NavigationEnd)).subscribe((event) => {
      this.isFaultAnalysisTab = event.url.includes('fault-analysis');
    });
    
    this.organizationPermission$?.pipe(takeUntil(this.ngDestroyed$)).subscribe((orgPer) => {
      this.isVezet = orgPer?.organization?.id === 100258802;
    });
  }

  public get Visibility() {
    return this.isVezet && this.isFaultAnalysisTab && !environment.production;
  }

  resetData() {
    this.isLoading = true;
    this.service.createData().subscribe(() => {
      this.isLoading = false;
      window.location.reload();
    });
  }
}
