import { Component, DestroyRef, OnInit } from '@angular/core';
import {
  ApiErrorResponse,
  ButtonV2Module,
  ColumnDefinition,
  ErrorHandlerV2Service,
  SnackbarService,
  TableServiceV2,
  TableV2Module,
} from '@gea/digital-ui-lib';
import {
  EDIT_MACHINE_ACTION,
  machineColumnDefinitionsConfig,
} from '@tab-container/machine-overview/table-config/machine-column-definitions.config';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Select, Store } from '@ngxs/store';
import { MachineOverviewModel, Permission } from '@shared/models';
import { combineLatest, filter, first, Observable, switchMap, tap } from 'rxjs';
import { FetchMachines, MachinesState, OrganizationsState, SetMachineOverviewData } from '@shared/state';
import { OrganizationsApiService } from '@shared/services';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'gea-hrt-machine-overview',
  templateUrl: './machine-overview.component.html',
  styleUrl: './machine-overview.component.scss',
  imports: [TableV2Module, ButtonV2Module, TranslateModule],
})
export class MachineOverviewComponent implements OnInit {
  machineOverviewData: MachineOverviewModel[] = [];
  columnDefinitions: ColumnDefinition[] = [];
  readonly TABLE_ID = 'machine-overview-table';
  hasMachinesChanged = false;
  loading = false;
  totalRecords = 0;

  @Select(OrganizationsState.permission) permissions$!: Observable<Permission>;

  constructor(
    private readonly store: Store,
    private readonly destroyRef: DestroyRef,
    private readonly organizationsApi: OrganizationsApiService,
    private readonly errorHandler: ErrorHandlerV2Service,
    private readonly snackbarService: SnackbarService,
    private readonly tableV2Service: TableServiceV2
  ) {}

  ngOnInit(): void {
    this.loadInitialData();
    this.setupTableActions();
  }

  private loadInitialData(): void {
    const machines$ = this.store.selectSnapshot(MachinesState.machines).length
      ? this.initializeComponent()
      : this.store.dispatch(new FetchMachines()).pipe(switchMap(() => this.initializeComponent()));

    machines$.subscribe({
      error: (error) => this.errorHandler.handleError(error),
    });
  }

  private initializeComponent(): Observable<MachineOverviewModel[]> {
    return combineLatest([this.permissions$, this.store.dispatch(new SetMachineOverviewData())]).pipe(
      switchMap(([permissions]) => {
        this.columnDefinitions = machineColumnDefinitionsConfig(permissions);
        return this.store.select(MachinesState.machineOverviewData);
      }),
      filter((machines) => machines.length > 0),
      first(),
      tap((machines) => {
        this.machineOverviewData = machines;
        this.totalRecords = machines.length;
      })
    );
  }

  private setupTableActions(): void {
    this.tableV2Service.actions
      .pipe(
        filter((action) => action.tableId === this.TABLE_ID && action.action === EDIT_MACHINE_ACTION),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((action) => this.updateMachine(action.rowData));
  }

  saveMachineNames(): void {
    const machines = this.machineOverviewData.map((m) => ({ id: m.machine.id, name: m.machine.name }));
    const organizationId = this.store.selectSnapshot(OrganizationsState.selectedId);

    if (!organizationId) return;

    this.loading = true;
    this.organizationsApi.updateMachineNames(organizationId, machines).subscribe({
      next: () => this.onSaveSuccess(),
      error: (error: ApiErrorResponse) => this.onSaveError(error),
    });
  }

  private updateMachine(updatedMachine: MachineOverviewModel): void {
    const index = this.machineOverviewData.findIndex((m) => m.machine.id === updatedMachine.machine.id);

    if (index !== -1) {
      this.machineOverviewData = [
        ...this.machineOverviewData.slice(0, index),
        updatedMachine,
        ...this.machineOverviewData.slice(index + 1),
      ];
      this.hasMachinesChanged = true;
    }
  }

  private onSaveSuccess(): void {
    this.loading = false;
    this.hasMachinesChanged = false;
    this.store.dispatch(new FetchMachines());
    this.snackbarService.add({
      summary: 'MACHINE_OVERVIEW.SNACKBAR.SAVE_MACHINE.SUMMARY',
      detail: 'MACHINE_OVERVIEW.SNACKBAR.SAVE_MACHINE.DETAIL',
      severity: 'success',
    });
  }

  private onSaveError(error: ApiErrorResponse): void {
    this.loading = false;
    this.errorHandler.handleError(error);
    this.snackbarService.add({
      summary: 'MACHINE_OVERVIEW.SNACKBAR.SAVE_MACHINE.ERROR',
      detail: error.message || 'An unknown error occurred',
      severity: 'error',
    });
  }
}
