import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SystemHealthCheck, SystemHealthMachineComponent, SystemHealthStatus } from '@shared/models';

import { HealthHeaderComponent } from '@system-health/component-header';
import { HealthCheckComponent } from '@system-health/health-check';

import { AccordionModule } from 'primeng/accordion';

@Component({
  imports: [CommonModule, AccordionModule, HealthCheckComponent, HealthHeaderComponent],
  selector: 'gea-hrt-system-health-machine-component',
  templateUrl: './machine-component.component.html',
  styleUrls: ['./machine-component.component.scss'],
})
export class HealthMachineComponent {
  @Input({ required: true }) component!: SystemHealthMachineComponent;
  @Input({ required: true }) organizationId!: number;
  @Input({ required: true }) machineId!: number;

  get sortedHealthChecks() {
    const healthy: SystemHealthCheck[] = [];
    const errors: SystemHealthCheck[] = [];
    const warnings: SystemHealthCheck[] = [];
    const incomplete: SystemHealthCheck[] = [];
    const infos: SystemHealthCheck[] = [];

    this.component.healthChecks.forEach((check) => {
      switch (check.status) {
        case SystemHealthStatus.ERROR:
          errors.push(check);
          break;
        case SystemHealthStatus.WARNING:
          warnings.push(check);
          break;
        case SystemHealthStatus.HEALTHY:
          healthy.push(check);
          break;
        case SystemHealthStatus.INFO:
          infos.push(check);
          break;
      }
    });

    return [...errors, ...warnings, ...incomplete, ...healthy, ...infos];
  }
}
