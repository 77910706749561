import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { first, skipWhile, takeUntil } from 'rxjs/operators';
import { SelectOption, SnackbarService } from '@gea/digital-ui-lib';

import { BaseFault, FaultAnalysisHistoryItem, Organization, Shutdown, Warning } from '@shared/models';

import {
  ClearFaults,
  FaultsState,
  FetchFaultAnalysis,
  MachinesState,
  OrganizationsState,
  RecommendationsState,
  ResetFaultAnalysis,
  SetSelectedFaultCodes,
  SetSelectedMachinesForFaultAnalysis,
} from '@shared/state';

import { DateHelper } from '@shared/helpers';
import { CommonModule } from '@angular/common';
import { FaultFiltersComponent, FaultAnalysisSectionComponent, FaultHistoryComponent } from './';
import { ToSelectOptionsPipe } from '@shared/pipes';
import { SpinnerComponent } from '@shared/components/';

@Component({
  imports: [
    CommonModule,
    FaultFiltersComponent,
    FaultAnalysisSectionComponent,
    FaultHistoryComponent,
    ToSelectOptionsPipe,
    SpinnerComponent,
  ],
  selector: 'gea-hrt-fault-analysis',
  templateUrl: './fault-analysis.component.html',
  styleUrls: ['./fault-analysis.component.scss'],
})
export class FaultAnalysisComponent implements OnInit, OnDestroy {
  @Select(MachinesState.selectedForFaultAnalyses) selectedMachines$!: Observable<number[]>;
  @Select(MachinesState.machines) machinesOptions$!: Observable<SelectOption[]>;

  @Select(FaultsState.faultCodeGroups) faultCodeGroups$!: Observable<SelectOption[]>;
  @Select(FaultsState.shutdowns) shutdowns$!: Observable<Shutdown[]>;
  @Select(FaultsState.warnings) warnings$!: Observable<Warning[]>;
  @Select(FaultsState.faultHistory) faultHistory$!: Observable<FaultAnalysisHistoryItem[]>;
  @Select(FaultsState.selected) selectedFaults$!: Observable<number[]>;

  @Select(RecommendationsState.problemSolvedSuccess) problemSolvedSuccess$!: Observable<boolean>;
  @Select(OrganizationsState.selected) organization$!: Observable<Organization>;

  readonly ngDestroyed$ = new Subject<void>();

  dateRange: SelectOption<number> = { value: 28 };
  faultOptions?: SelectOption<number>[];
  machineOptions?: SelectOption<number>[];
  selectedStatus: string[] = ['PENDING'];
  constructor(
    readonly snackBar: SnackbarService,
    readonly router: Router,
    readonly store: Store
  ) {}

  ngOnInit() {
    this.initializeStreams();
  }

  ngOnDestroy() {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }

  private initializeStreams() {
    const machinesReady$ = this.machinesOptions$.pipe(
      skipWhile((options) => options.length < 1),
      first()
    );
    const faultsReady$ = this.faultCodeGroups$.pipe(
      skipWhile((options) => options.length < 1),
      first()
    );

    combineLatest([machinesReady$, faultsReady$])
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(([machineOptions, faultOptions]) => {
        this.machineOptions = machineOptions;
        this.faultOptions = faultOptions;
        this.initializeDefaultSelections(machineOptions, faultOptions);
      });

    this.subscribeToMachinesOptions();
    this.subscribeToFaultCodeGroups();
    this.subscribeToProblemSolvedSuccess();
    this.subscribeToCustomer();
  }

  private subscribeToCustomer() {
    this.organization$.pipe(takeUntil(this.ngDestroyed$)).subscribe(() => {
      this.dateRange = { value: 28 };
    });
  }

  private subscribeToMachinesOptions() {
    this.machinesOptions$
      .pipe(
        skipWhile((options) => options.length < 1),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe((machineOptions) => {
        this.machineOptions = machineOptions;
        if (!this.store.selectSnapshot<number[]>(MachinesState.selectedForFaultAnalyses).length) {
          this._setSelectedMachines(machineOptions.map(({ value }) => value));
        }
      });
  }

  private subscribeToFaultCodeGroups() {
    this.faultCodeGroups$
      .pipe(
        skipWhile((options) => options.length < 1),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe((faultOptions) => {
        this.faultOptions = faultOptions;
        if (!this.store.selectSnapshot<number[]>(FaultsState.selected).length) {
          this.store.dispatch(new SetSelectedFaultCodes(faultOptions.map(({ value }) => value)));
        }
      });
  }

  private subscribeToProblemSolvedSuccess() {
    this.problemSolvedSuccess$.pipe(takeUntil(this.ngDestroyed$)).subscribe((success) => {
      if (success) {
        this.snackBar.add({
          detail: 'TROUBLESHOOTING.PROBLEM_SOLVING.MESSAGE_SOLVE_SUCCESS',
          summary: 'TROUBLESHOOTING.PROBLEM_SOLVING.MESSAGE_SOLVE_SUCCESS',
          severity: 'success',
        });
      }
    });
  }

  private initializeDefaultSelections(machineOptions: SelectOption<number>[], faultOptions: SelectOption<number>[]) {
    if (!this.store.selectSnapshot(MachinesState.selectedForFaultAnalyses).length) {
      this._setSelectedMachines(machineOptions.map(({ value }) => value));
    }
    if (!this.store.selectSnapshot(FaultsState.selected).length) {
      this.store.dispatch(new SetSelectedFaultCodes(faultOptions.map(({ value }) => value)));
    }
    this.fetchFaultAnalysis();
  }

  private _setSelectedMachines(machines: number[]) {
    this.store.dispatch(new ClearFaults());
    this.store.dispatch(new SetSelectedMachinesForFaultAnalysis(machines));
  }

  resolveFaultCode(baseFault: BaseFault): Promise<boolean> {
    const { faultCode, machineId } = baseFault;
    const organizationId = this.store.selectSnapshot(OrganizationsState.selectedId);

    return this.router.navigate([`troubleshooting/${organizationId}`], {
      queryParams: { faultCode, machine: machineId },
    });
  }

  setSelectedMachines(machines: number[]) {
    this._setSelectedMachines(machines);
  }

  setSelectedFaultCodes(faultCodes: number[]) {
    if (faultCodes.length) {
      this.store.dispatch(new SetSelectedFaultCodes(faultCodes));
      this.fetchFaultAnalysis();
    } else {
      this.store.dispatch(new ResetFaultAnalysis());
    }
  }

  setDateRange(dateRange: number) {
    this.dateRange = { value: dateRange };
    this.fetchFaultAnalysis();
  }

  setStatus(status: string[]) {
    this.selectedStatus = status;
    this.fetchFaultAnalysis();
  }

  private fetchFaultAnalysis() {
    const machines = this.store.selectSnapshot(MachinesState.selectedForFaultAnalyses);
    const selectedFaultCodes = this.store.selectSnapshot(FaultsState.selected);
    const formattedDateRange = DateHelper.daysBack(this.dateRange.value);
    const formattedStatus = this.selectedStatus.map((s) => (s === 'PENDING' ? '0' : '1'));

    this.store.dispatch(
      new FetchFaultAnalysis(
        machines.length ? machines : [0],
        formattedDateRange,
        formattedStatus,
        selectedFaultCodes.length ? selectedFaultCodes : [0]
      )
    );
  }
}
