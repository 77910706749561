import { AppRoutingModule } from './app/app-routing.module';
import { NgModule, Provider } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { NgxsModule } from '@ngxs/store';
import { GeaTranslateHttpLoader, HeaderModule, UserState, AppEnvironmentState, ActionRequiredState } from '@gea/digital-ui-lib';
import { environment } from '@environments/environment';
import {
  MembershipsState,
  OrganizationsState,
  MachinesState,
  FaultsState,
  FaultRecommendationsState,
  RecommendationsState,
  RelatedOperatingDataState,
  SystemHealthState,
} from '@shared/state';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const translationFactory = (http: HttpClient) =>
  new GeaTranslateHttpLoader(http, environment.translationBlob, ['i18n/1/insightpartner-bluredcare/'], []);

const ngxsStates = [
  MembershipsState,
  UserState,
  AppEnvironmentState,
  ActionRequiredState,
  OrganizationsState,
  MachinesState,
  FaultsState,
  FaultRecommendationsState,
  RecommendationsState,
  RelatedOperatingDataState,
  SystemHealthState,
];

export const APP_PROVIDERS: Provider[] = [
  { provide: 'storageURL', useValue: 'https://strgaccappassetsdev.blob.core.windows.net/strg-container-i18n-dev/' },
  { provide: 'hrtBaseUrl', useValue: environment.hrt.baseURL || environment.baseURL },
  { provide: 'isAdminInLocal', useFactory: () => environment.name === 'local' && (environment.isAdminInLocal || false) },
  { provide: 'hrtFaultDatabaseBaseUrl', useValue: environment.hrtFaultdatabase?.baseUrl || '' },
  { provide: 'hrtFaultDatabaseSubscriptionKey', useValue: environment.hrtFaultdatabase?.subscriptionKey || '' },
  { provide: 'baseUrl', useValue: environment.baseURL },
  { provide: 'hrtSubscriptionKey', useValue: environment.hrt.subscriptionKey },
  { provide: 'subscriptionKey', useValue: environment.subscriptionKey },
  { provide: 'iconPath', useValue: 'https://content.apps.tst.gea.com/strg-container-icons-test/' },
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  { provide: 'appVersion', useFactory: () => require('../package.json').version },
];

@NgModule({
  imports: [
    RouterModule,
    NgxsModule.forRoot(ngxsStates, {
      developmentMode: !environment.production,
      compatibility: { strictContentSecurityPolicy: true },
    }),
    TranslateModule.forRoot({
      extend: true,
      defaultLanguage: 'en-US',
      loader: { provide: TranslateLoader, useFactory: translationFactory, deps: [HttpClient] },
    }),
    HeaderModule.forRoot({
      redirectUri: environment.oAuth.redirectUri,
      postLogoutRedirectUri: environment.oAuth.postLogoutRedirectUri,
      clientId: environment.oAuth.clientId,
      authority: environment.oAuth.authority,
      authorityDomain: environment.oAuth.authorityDomain,
      portalURL: environment.portal?.baseURL || '',
    }),
  ],

  exports: [RouterModule, AppRoutingModule, NgxsModule, TranslateModule, HeaderModule, BrowserAnimationsModule],
  providers: APP_PROVIDERS,
})
export class AppCommonModule {}
