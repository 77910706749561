import { Component, Output, EventEmitter, Input } from '@angular/core';
import { RecommendationDto, ProblemResolveDto } from '@shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { HeadingComponent, HintComponent } from '@shared/components';

import { RecommendationAction } from '.';
import { ProblemSolveComponent } from '@troubleshooting/problem-solve';
import { RecommendationsListComponent } from './recommendations-list/recommendations-list.component';

@Component({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    HeadingComponent,
    RecommendationsListComponent,
    ProblemSolveComponent,
    HintComponent,
    TranslateModule,
  ],
  selector: 'gea-hrt-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss'],
})
export class RecommendationsComponent {
  @Input({ required: true }) loading!: boolean;
  @Output() handleRecommendationAction = new EventEmitter<RecommendationAction>();
  @Output() emitProblemSolved = new EventEmitter<ProblemResolveDto | undefined>();

  @Input() recommendationText?: string;
  @Input({ required: true }) recommendations!: RecommendationDto[];
}
