import { ActivatedRoute, Router, RouterModule, Scroll } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import { Select } from '@ngxs/store';
import { OrganizationPermission } from '@shared/models';
import { OrganizationsState } from '@shared/state';
import {
  AboutPanelComponent,
  ButtonV2Module,
  ComplexDialogV3Service,
  IconModule,
  NavigationItem,
  SidebarV2Module,
  SidepanelTypes,
} from '@gea/digital-ui-lib';

import { Permission } from '@shared/models/permission.model';
import { CustomerOverviewComponent } from '@app/customer-overview';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonModule } from '@angular/common';
import { HintComponent } from '@app/shared/components';
import { ResetDemoaccoutComponent } from '@shared/components/reset-demoaccout/reset-demoaccout.component';

@Component({
  imports: [
    CommonModule,
    SidebarV2Module,
    TranslateModule,
    ButtonV2Module,
    IconModule,
    HintComponent,
    RouterModule,
    ResetDemoaccoutComponent,
  ],
  selector: 'gea-hrt-tab-container',
  templateUrl: './tab-container.component.html',
  styleUrls: ['./tab-container.component.scss'],
})
export class TabContainerComponent implements OnInit {
  @Select(OrganizationsState.selected) organizationPermission$?: Observable<OrganizationPermission>;

  navigationItems: NavigationItem[] = this.threeTabs();
  activeTab: NavigationItem = this.navigationItems[0];
  SidepanelTypes = SidepanelTypes;
  organizationId?: number;
  currentLanguage = '';
  ngDestroyed$ = new Subject<void>();

  AboutPanelComponent = AboutPanelComponent;

  constructor(
    @Inject('appVersion') readonly appVersion: string,
    readonly activatedRoute: ActivatedRoute,
    readonly complexDialogService: ComplexDialogV3Service,
    public readonly router: Router,
    readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.subscribeToOrgAndPermission();
    this.subscribeToRouterEvent();
    this.subscribeToTranslation();
  }

  private subscribeToTranslation() {
    this.translate.onLangChange.subscribe((event) => {
      this.currentLanguage = event.lang;
    });
  }

  lastPermission: Permission | undefined = undefined;

  private subscribeToOrgAndPermission() {
    this.organizationPermission$
      ?.pipe(
        takeUntil(this.ngDestroyed$),
        filter((organization) => {
          if (!organization) {
            this.openCustomerOverview();
          } else {
            this.organizationId = organization?.organization?.id;
          }

          return !!organization;
        })
      )
      .subscribe((orgPer) => {
        this.updateTabs(orgPer.permission);
        this.initializeOrganization(orgPer);
      });
  }

  private subscribeToRouterEvent() {
    this.router.events
      .pipe(
        filter((event): event is Scroll => event instanceof Scroll),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe((event) => {
        const url = event.routerEvent.url.split('/')[1];
        const activeTab = this.navigationItems.find((tab) => tab.route.includes(url)) || this.activeTab;
        this.setActiveTab(activeTab);
      });
  }

  private setActiveTab(tab: NavigationItem) {
    this.activeTab = tab;
  }

  private updateTabs(permission: Permission) {
    if (['GEA_App_Administrator', 'GEA_App_Operator', 'admin', 'APP_hrt_insightpartner-bluredcare_admin'].includes(permission)) {
      this.navigationItems = this.allTabs();
    } else if (permission === 'executive_board') {
      this.navigationItems = this.twoTabs();
    } else if (['customer', 'customerAdmin', 'maintenance_manager', 'technician'].includes(permission)) {
      this.navigationItems = this.threeTabs();
    }
  }

  private allTabs(): NavigationItem[] {
    const { organizationId } = this;

    return [
      {
        label: 'TAB_CONTAINER.TABS.FAULT_ANALYSIS',
        route: `fault-analysis/${organizationId}`,
        icon: 'candlestick-chart',
      },
      {
        label: 'TAB_CONTAINER.TABS.SYSTEM_HEALTH_STATUS',
        route: `system-health/${organizationId}`,
        icon: 'add-fav',
      },
      {
        label: 'TAB_CONTAINER.TABS.FAULT_RECOMMENDATIONS',
        route: `recommendations/${organizationId}`,
        icon: 'digital-key',
      },
      {
        label: 'TAB_CONTAINER.TABS.TROUBLESHOOTING',
        route: `troubleshooting/${organizationId}`,
        icon: 'api',
      },
      {
        label: 'TAB_CONTAINER.TABS.MACHINE_OVERVIEW',
        route: `machine-overview/${organizationId}`,
        icon: 'grid-interface',
      },
    ];
  }

  private threeTabs(): NavigationItem[] {
    const { 0: faultAnalysis, 3: troubleShooting, 4: machineOverview } = this.allTabs();

    return [faultAnalysis, troubleShooting, machineOverview];
  }

  private twoTabs(): NavigationItem[] {
    const { 0: faultAnalysis, 3: troubleShooting } = this.allTabs();

    return [faultAnalysis, troubleShooting];
  }

  dialogRef?: DynamicDialogRef<CustomerOverviewComponent>;
  openCustomerOverview() {
    this.dialogRef = this.complexDialogService.open(
      {
        header: 'CUSTOMER_OVERVIEW.TITLE',
        closable: true,
        hideButtons: true,
      },
      CustomerOverviewComponent
    ).ref;
  }

  lastRoute: string | undefined = '';
  protected async route(tab: NavigationItem) {
    if (!this.organizationId) {
      if (this.lastRoute === tab.label) return;

      this.lastRoute = tab.label;
      this.openCustomerOverview();
      return;
    }

    await this.router.navigate([tab.route, this.organizationId]);
    this.setActiveTab(tab);
  }

  initializeOrganization({ permission, organization }: OrganizationPermission) {
    this.organizationId = organization?.id;
    this.updateTabs(permission);

    const organizationIdFromActivatedRoute = this.activatedRoute.firstChild?.snapshot.params['organizationId'];

    if (!organization && !organizationIdFromActivatedRoute) {
      this.openCustomerOverview();
    } else if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  openDataPrivacy() {
    window.open('../../assets/documents/Dataprivacy' + this.currentLanguage + '.pdf', '_blank');
  }

  openTermsAndConditions() {
    window.open('../../assets/documents/TandC' + this.currentLanguage + '.pdf', '_blank');
  }

  openImprint() {
    window.open('../../assets/documents/Imprint' + this.currentLanguage + '.pdf', '_blank');
  }

  openCookieSettings() {
    // @ts-expect-error Property 'OneTrust' does not exist on type 'Window & typeof globalThis'.ts(2339)
    window.OneTrust.ToggleInfoDisplay();
  }
}
