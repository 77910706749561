import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { SystemHealthCheck } from '@app/shared/models';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MatDialogModule } from '@angular/material/dialog';
import { IconModule } from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';

import { HeadingComponent, UpdateTimeComponent } from '@shared/components';
import { ToTitlePipe } from '@shared/pipes';
import { HealthDiagramComponent } from '../health-diagram';

@Component({
  imports: [
    CommonModule,
    HeadingComponent,
    IconModule,
    MatDialogModule,
    UpdateTimeComponent,
    TranslateModule,
    ToTitlePipe,
    HealthDiagramComponent,
  ],
  selector: 'gea-hrt-health-check-dialog',
  templateUrl: './health-check-dialog.component.html',
  styleUrl: './health-check-dialog.component.scss',
})
export class HealthCheckDialogComponent {
  public data: SystemHealthCheck;
  constructor(@Inject(DynamicDialogConfig) config: DynamicDialogConfig) {
    this.data = config.data;
  }

  computedClass() {
    const className = {
      0: 'healthy',
      1: 'warning',
      2: 'error',
      '-1': 'info',
    }[this.data.status];
    return `${className}`;
  }

  get metadata(): [string, number][] {
    // @ts-expect-error Type '[string, string | number][]' is not assignable to type '[string, number][]'.
    return Object.entries(this.data.healthStatusMetadata);
  }
}
