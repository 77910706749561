import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ButtonV2Module, InputV2Module, TextfieldV2Module } from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';

import { ProblemSolveDialogComponent } from '../';

import { HeadingComponent, CloseButtonComponent } from '@shared/components';

@Component({
  imports: [
    HeadingComponent,
    CloseButtonComponent,
    MatDialogModule,
    InputV2Module,
    TextfieldV2Module,
    ButtonV2Module,
    TranslateModule,
  ],
  selector: 'gea-hrt-app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss'],
})
export class ContactDialogComponent {
  formGroup: FormGroup;

  constructor(readonly dialogRef: MatDialogRef<ProblemSolveDialogComponent>) {
    const { email, required, minLength, maxLength, pattern } = Validators;

    this.formGroup = new FormGroup({
      email: new FormControl('', [required, email, minLength(10), maxLength(100), pattern(/^\S+@\S+\.\S+$/)]),
      phone: new FormControl('', [required, minLength(4), pattern(/^[-+()0-9]+/)]),
      title: new FormControl('', [required, minLength(10), maxLength(100)]),
      message: new FormControl('', [required, minLength(10), maxLength(1000)]),
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.formGroup.value);
  }
}
