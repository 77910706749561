import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SystemHealthConnectivity } from '@shared/models';
import { TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  imports: [CommonModule, TooltipModule],
  selector: 'gea-hrt-connectivity-status',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<span
    *ngIf="connectivity?.component === 'Panel'"
    class="status {{ connectivity?.signalStatus?.isAvailable ? 'available' : 'unavailable' }}"
    [pTooltip]="text"></span> `,
  styles: `
    .status {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      display: block;
    }
    .unavailable {
      background-color: red;
    }
    .available {
      background-color: green;
    }
  `,
})
export class ConnectivityStatusComponent {
  @Input() connectivity?: SystemHealthConnectivity;

  constructor(readonly translateService: TranslateService) {}

  get text() {
    const options: Intl.DateTimeFormatOptions = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    };
    const noSignals = this.translateService.instant('SYSTEM_HEALTH.CONNECTIVITY_STATUS.TOOLTIP.NO_SIGNALS');
    const lastSignal = this.translateService.instant('SYSTEM_HEALTH.CONNECTIVITY_STATUS.TOOLTIP.LAST_SIGNAL');
    const dataReceived = this.translateService.instant('SYSTEM_HEALTH.CONNECTIVITY_STATUS.TOOLTIP.DATA_RECEIVED');
    if (this.connectivity === undefined) return '';
    const timeString =
      this.connectivity.signalStatus.lastSignal.toString() == '0001-01-01T00:00:00'
        ? noSignals
        : this.connectivity.signalStatus.lastSignal.toLocaleString(undefined, options);

    return `${lastSignal}: ${timeString} 
            ${dataReceived}: ${this.connectivity.signalStatus.receivedPercentage.toFixed(2)}%`;
  }
}
