import { Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { RecommendationDto } from '@app/shared/models';
import { ButtonV2Module } from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';
import { CloseButtonComponent, HeadingComponent } from '@shared/components';

@Component({
  imports: [HeadingComponent, ButtonV2Module, CloseButtonComponent, MatDialogModule, TranslateModule],
  selector: 'gea-hrt-delete-recommendation-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteRecommendationDialogComponent {
  constructor(public dialogRef: MatDialogRef<DeleteRecommendationDialogComponent>) {}

  recommendation?: RecommendationDto;

  commit() {
    this.dialogRef.close(this.recommendation);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
