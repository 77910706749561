import { APP_PROVIDERS, AppCommonModule } from './app-common.module';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppComponent } from '@app/app.component';
import { environment } from '@environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [provideAnimations(), importProvidersFrom(AppCommonModule), ...APP_PROVIDERS],
}).catch((err) => console.debug(err));
