<div class="troubleshooting-container">
  <div class="troubleshooting-section">
    <ng-container *ngIf="machineOptions$ | async as machineOptions">
      <ng-container *ngIf="faultOptions$ | async as faultOptions">
        <gea-hrt-ts-filters
          [faultOptions]="faultOptions"
          [machineOptions]="machineOptions"
          [selectedMachine]="selectedMachine"
          [selectedFault]="selectedFault ?? faultOptions?.[0]?.value"
          (selectMachine)="setSelectedMachine($event)"
          (selectFaultCode)="setSelectedFaultCode($event)" />
      </ng-container>
    </ng-container>
  </div>
  <div class="troubleshooting-section">
    <gea-hrt-info-box
      *ngIf="(recommendationFaultStatus$ | async) !== FaultStatus.SOLVED"
      [withBorder]="false"
      boxTitle="{{ 'TROUBLESHOOTING.RECOMMENDATIONS.TITLE' | translate }}"
      tooltip="{{ 'TROUBLESHOOTING.RECOMMENDATIONS.TOOLTIP' | translate }}">
      <ng-container *ngIf="recommendations$ | async as recommendations">
        <gea-hrt-recommendations
          [loading]="recommendations === undefined"
          [recommendations]="recommendations ?? []"
          [recommendationText]="(recommendationText$ | async) ?? ''"
          (handleRecommendationAction)="handleRecommendationAction($event)"
          (emitProblemSolved)="dispatchProblemSolved($event)" />
      </ng-container>
      <ng-template #noMachineOrFaultSelected
        ><gea-hrt-hint>{{ 'HINT.NO_MACHINE_OR_FAULT' | translate }}</gea-hrt-hint></ng-template
      >
    </gea-hrt-info-box>
  </div>
  <div class="troubleshooting-section">
    <gea-hrt-info-box
      [withBorder]="false"
      boxTitle="{{ 'TROUBLESHOOTING.RELATED_OPERATING_DATA.TITLE' | translate }}"
      tooltip="{{ 'TROUBLESHOOTING.RELATED_OPERATING_DATA.TOOLTIP' | translate }}">
      <gea-hrt-related-operating-data [measurements]="measurements$ | async" (setDateRange)="setDateRange($event)" />
    </gea-hrt-info-box>
  </div>
</div>
