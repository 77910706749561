import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RecommendationDto, RecommendationStatus } from '@app/shared/models';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RecommendationAction } from '../recommendations-list';
import { CommonModule } from '@angular/common';
import { ButtonV2Module, IconModule } from '@gea/digital-ui-lib';

@Component({
  imports: [CommonModule, ButtonV2Module, IconModule, TranslateModule],
  selector: 'gea-hrt-recommendation-readonly-actions',
  styleUrl: 'readonly-actions.component.scss',
  templateUrl: './readonly-actions.component.html',
})
export class ReadonlyActionsComponent {
  Status = RecommendationStatus;

  @Input({ required: true }) recommendation!: RecommendationDto;

  @Output() actionClicked = new EventEmitter<RecommendationAction>();

  constructor(public readonly translate: TranslateService) {}
}
