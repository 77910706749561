import { Component, OnDestroy, OnInit } from '@angular/core';
import { AnnunciationsMetaData, FaultCatalog, Permission, RecommendationDto, ResolvedFault } from '@shared/models';
import { ButtonV2Module, SelectOption, SnackbarService } from '@gea/digital-ui-lib';
import {
  ClearRecommendations,
  CreateRecommendation,
  CreateRecommendationFromResolvedFault,
  DeleteRecommendation,
  FaultRecommendationsState,
  FetchFaultCatalog,
  FetchRecommendationsByFault,
  RecommendationsState,
  UpdateRecommendation,
  FetchResolvedFaults,
  FetchAnnunciationsMetadata,
  ValidateRecommendation,
  OrganizationsState,
} from '@shared/state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { Router, ActivatedRoute } from '@angular/router';

import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { RecommendationOrderListComponent } from './recommendation-order-list';
import { FaultRecommendationsFiltersComponent } from './filters';
import { SolvedFaultResponse, SolvedFaultsComponent } from './solved-faults';
import { RecommendationDialogComponent } from './dialog';

import { RecommendationsListComponent } from '@troubleshooting/recommendations';

import { InfoBoxComponent, HintComponent } from '@shared/components';

@Component({
  imports: [
    CommonModule,
    InfoBoxComponent,
    HintComponent,
    ButtonV2Module,
    TranslateModule,
    FaultRecommendationsFiltersComponent,
    RecommendationOrderListComponent,
    RecommendationsListComponent,
    SolvedFaultsComponent,
  ],
  selector: 'gea-hrt-fault-recommendations',
  templateUrl: './fault-recommendations.component.html',
  styleUrls: ['./fault-recommendations.component.scss'],
})
export class FaultRecommendationsComponent implements OnInit, OnDestroy {
  closeDialog$!: Subscription;

  selectedFaultCode?: number;
  hasRecommendations?: boolean;
  selectedFaultName?: string;

  editOrderModeOn = false;
  ngDestroyed$ = new Subject<void>();
  faultCatalog: FaultCatalog = [];
  resolvedFaults?: ResolvedFault[];

  @Select(FaultRecommendationsState.faultCatalog) faultCatalog$?: Observable<FaultCatalog>;
  @Select(FaultRecommendationsState.annunciationsMetadata) annunciationsMetadata$?: Observable<AnnunciationsMetaData>;
  @Select(FaultRecommendationsState.resolvedFaults) resolvedFaults$?: Observable<ResolvedFault[]>;

  @Select(RecommendationsState.recommendations) recommendations$?: Observable<RecommendationDto[]>;
  @Select(OrganizationsState.permission) permission$?: Observable<Permission>;

  constructor(
    readonly store: Store,
    readonly dialog: MatDialog,
    readonly snackBar: SnackbarService,
    readonly router: Router,
    readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.fetchResolvedFaults();
    this.fetchMetadata();
    this.subscribeToQueryParams();
    this.subscribeToFaultCatalog();
    this.subscribeToResolvedFaults();
  }

  ngOnDestroy() {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }

  private fetchResolvedFaults() {
    this.store.dispatch(new FetchResolvedFaults());
  }

  private fetchMetadata() {
    this.store.dispatch(new FetchAnnunciationsMetadata());
  }

  private subscribeToQueryParams() {
    this.route.queryParams.pipe(takeUntil(this.ngDestroyed$)).subscribe((params) => {
      if (this.selectedFaultCode !== undefined || this.hasRecommendations !== undefined) {
        return;
      }

      this.setHasRecommendations(params['hasRecommendations'] === 'true');

      // @ts-expect-error Argument of type 'number' is not assignable to parameter of type 'SelectOption<number>'.ts(2345)
      this.faultChanged(Number(params['faultCode']));
    });
  }

  private subscribeToFaultCatalog() {
    this.faultCatalog$?.pipe(takeUntil(this.ngDestroyed$)).subscribe((faultCatalog) => {
      if (!faultCatalog.length) {
        return;
      }

      this.faultCatalog = faultCatalog;
      this.updateSelectedFaultNameFilter();

      if (!this.selectedFaultCode) {
        this.updateSelectedFaultCode(faultCatalog[0].id);
      }
    });
  }

  private subscribeToResolvedFaults() {
    this.resolvedFaults$
      ?.pipe(takeUntil(this.ngDestroyed$))
      .subscribe((resolvedFaults) => (this.resolvedFaults = resolvedFaults));
  }

  private setQueryParams(queryParams: { faultCode?: number; hasRecommendations?: boolean }) {
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' }).catch(() => {
      console.warn('query param could not be set');
    });
  }

  faultChanged(temp?: SelectOption<number>) {
    // @ts-expect-error Type 'SelectOption<number>' is not assignable to type 'number'.ts(2322)
    const faultCode: number = temp;

    if (faultCode === this.selectedFaultCode) {
      return;
    }

    this.updateSelectedFaultCode(faultCode);
  }

  updateSelectedFaultCode(faultCode: number) {
    this.selectedFaultCode = faultCode;

    this.updateSelectedFaultNameFilter();
    this.setQueryParams({ faultCode });
    this.fetchRecommendations();
  }

  setHasRecommendations(hasRecommendations: boolean) {
    this.selectedFaultCode = undefined;
    this.hasRecommendations = hasRecommendations;

    this.setQueryParams({ hasRecommendations });
    this.fetchFaultCatalog();
  }

  openAddRecommendationDialog() {
    const dialog = this.dialog.open(RecommendationDialogComponent, {
      minWidth: '50vw',
      minHeight: '50vh',
      autoFocus: true,
    });

    this.closeDialog$ = dialog
      .afterClosed()
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(({ actionName, images }: { actionName: string; images: [] }) => {
        if (!actionName) {
          return;
        }

        const faultCode = this.selectedFaultCode;

        if (!faultCode) {
          return;
        }

        this.store.dispatch(new CreateRecommendation(faultCode, actionName, images)).subscribe(() =>
          this.snackBar.add({
            detail: 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DIALOG.NEW_RECOMMENDATION_SAVED_SUCCESSFULLY',
            summary: 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DIALOG.NEW_RECOMMENDATION_SAVED_SUCCESSFULLY',
            severity: 'success',
          })
        );
      });
  }

  fetchFaultCatalog() {
    const { store, hasRecommendations } = this;

    store.dispatch(new ClearRecommendations());
    store.dispatch(new FetchFaultCatalog(undefined, hasRecommendations));
  }

  fetchRecommendations() {
    const { store, selectedFaultCode } = this;

    store.dispatch(new ClearRecommendations());
    if (selectedFaultCode) {
      store.dispatch(new FetchRecommendationsByFault(selectedFaultCode));
    }
  }

  saveRecommendation(recommendation: RecommendationDto) {
    if (recommendation.actionName === null) {
      return;
    }

    this.store.dispatch(new UpdateRecommendation(recommendation)).subscribe(() => {
      this.snackBar.add({
        detail: 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DIALOG.RECOMMENDATION_UPDATED_SUCCESSFULLY',
        summary: 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DIALOG.RECOMMENDATION_UPDATED_SUCCESSFULLY',
        severity: 'success',
      });
    });
  }

  confirmDeleteRecommendation({ id }: RecommendationDto) {
    this.store.dispatch(new DeleteRecommendation(id)).subscribe(() => {
      this.snackBar.add({
        detail: 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DELETE_DIALOG.RECOMMENDATION_DELETED_SUCCESSFULLY',
        summary: 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DELETE_DIALOG.RECOMMENDATION_DELETED_SUCCESSFULLY',
        severity: 'success',
      });
    });
  }

  setReviewStatus({ resolvedFault, reviewStatus }: SolvedFaultResponse) {
    this.store.dispatch(new CreateRecommendationFromResolvedFault(resolvedFault, reviewStatus)).subscribe(() => {
      this.fetchResolvedFaults();
    });
  }

  validateRecommendation(recommendation: RecommendationDto) {
    this.store.dispatch(new ValidateRecommendation({ ...recommendation, validationCount: 2 })).subscribe(() => {
      this.snackBar.add({
        detail: 'FAULT_RECOMMENDATIONS.VALIDATION_SUCCESS_TITLE',
        summary: 'FAULT_RECOMMENDATIONS.VALIDATION_SUCCESS_SUMMARY',
        severity: 'success',
      });
    });
  }

  /**
   * For some unknown reason, the faultName dropdown will only refresh this way
   */
  private updateSelectedFaultNameFilter() {
    this.selectedFaultName = undefined;
    setTimeout(() => {
      this.selectedFaultName = this.faultCatalog.find((fault) => fault.id === this.selectedFaultCode)?.name ?? ' ';
    }, 0);
  }
}
