import { Component, inject, OnInit } from '@angular/core';
import { ButtonV2Module, ColumnRendererComponent, IconModule, TableServiceV2 } from '@gea/digital-ui-lib';
import { Shutdown, Warning } from '@shared/models';
import { Fix_ACTION } from '@fault-analysis/table-config/fault-analysis-column-definitions';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'gea-hrt-link-button-renderer',
  standalone: true,
  imports: [TranslateModule, IconModule, ButtonV2Module],
  template: `
    @if (showButton) {
      <div>
        <geaui-button-v2 type="secondary" (click)="onClick()">
          <geaui-icon-v2 file="fix_key" class="fix_key" />
          <span>
            {{ 'FAULT_ANALYSIS.FIX_BUTTON' | translate }}
          </span>
        </geaui-button-v2>
      </div>
    }
  `,
  styles: `
    ::ng-deep .p-button {
      height: unset !important;
    }
    ::ng-deep span.p-button-content {
      display: flex !important;
      align-items: center;
      justify-content: center;
      gap: 2px;
    }
  `,
})
export class LinkButtonRendererComponent implements OnInit, ColumnRendererComponent<Warning | Shutdown> {
  rowData?: Warning | Shutdown;
  tableService = inject(TableServiceV2);
  tableId?: string;
  showButton = false;

  ngOnInit(): void {
    if (this.rowData && this.rowData.countPending > 0) {
      this.showButton = true;
    }
  }

  onClick() {
    if (!this.tableId) return;
    this.tableService.publishAction(this.tableId, Fix_ACTION, this.rowData);
  }
}
