import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  imports: [CommonModule],
  selector: 'gea-hrt-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadingComponent {
  @Input() text: string | undefined;
  @Input() type: 'main' | 'sub' | 'minor' | 'tiny' = 'main';
  @Input() disabled = false;
  // blur title on small screens - in conjunction with a "switch to landscape" overlay
  @Input() blur = false;
}
