import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';
import { IconModule } from '@gea/digital-ui-lib';
import { Component, Input } from '@angular/core';
import { SystemHealthMachineComponent } from '@app/shared/models';
import { HeadingComponent, ConnectivityStatusComponent } from '@shared/components';
import { CommonModule } from '@angular/common';

@Component({
  imports: [CommonModule, IconModule, TooltipModule, TranslateModule, HeadingComponent, ConnectivityStatusComponent],
  selector: 'gea-hrt-health-component-header',
  templateUrl: './component-header.component.html',
  styleUrl: './component-header.component.scss',
})
export class HealthHeaderComponent {
  @Input({ required: true }) component!: SystemHealthMachineComponent;
}
